:root {
  --background: #282a36;
  --current-line: #44475a;
  --foreground: #f8f8f2;
  --comment: #6272a4;
  --cyan: #8be9fd;
  --green: #50fa7b;
  --orange: #ffb86c;
  --pink: #ff79c6;
  --purple: #bd93f9;
  --red: #ff5555;
  --yellow: #f1fa8c;
}

body {
  background-color: var(--background);
  color: var(--foreground);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.platform-redirect {
  background-color: var(--current-line);
  padding: 0.75rem 2rem;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1100;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
  border: 1px solid var(--comment);
}

.platform-redirect-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--foreground);
  position: relative;
}

.platform-redirect-close {
  background: none;
  border: none;
  color: var(--comment);
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0;
  line-height: 1;
  position: absolute;
  right: -1.5rem;
  top: 50%;
  transform: translateY(-50%);
  transition: color 0.2s;
}

.platform-redirect-close:hover {
  color: var(--pink);
}

.navbar {
  top: 2.5rem;
  background-color: var(--current-line);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-sizing: border-box;
}

.navbar-brand {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navbar-brand a {
  color: var(--purple);
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.company-logo {
  height: 37px;
  width: auto;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0 2rem;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-link {
  color: var(--foreground);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.nav-link:hover {
  background-color: var(--comment);
}

.btn-primary {
  background-color: var(--purple);
  color: var(--background);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.btn-secondary {
  background-color: transparent;
  color: var(--foreground);
  border: 1px solid var(--purple);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.btn-primary:hover {
  opacity: 0.9;
}

.btn-secondary:hover {
  background-color: var(--current-line);
}

.home-page {
  padding-top: 64px;
}

.hero-section {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(135deg, var(--background) 0%, var(--current-line) 100%);
  padding: 2rem;
}

.hero-content {
  max-width: 800px;
}

.hero-content h1 {
  font-size: 4.5rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(120deg, var(--purple), var(--pink));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
  font-weight: 800;
  letter-spacing: -0.02em;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: var(--comment);
  margin-bottom: 2rem;
}

.hero-cta {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.features-section {
  padding: 4rem 2rem;
  background-color: var(--current-line);
}

.features-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: var(--purple);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  background-color: var(--background);
  padding: 2rem;
  border-radius: 8px;
  transition: transform 0.2s;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card h3 {
  color: var(--cyan);
  margin-bottom: 1rem;
}

.feature-card p {
  color: var(--comment);
  line-height: 1.6;
}

.token-generator {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.token-generator h2,
.token-generator p,
.token-generator .api-key-list-title {
  text-align: left;
  width: 100%;
}

.token-generator .mb-8 {
  margin-bottom: 2rem;
}

.token-generator .api-key-list-section {
  width: 100%;
}

textarea {
  width: 100%;
  min-height: 200px;
  margin-bottom: 20px;
  padding: 10px;
}

.response {
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.user-profile {
  position: relative;
  display: inline-block;
}

.profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-icon span {
  font-size: 1.5rem;
}

.profile-panel {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background: var(--current-line);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
  margin-top: 10px;
  z-index: 1000;
  padding: 20px;
  border: 1px solid var(--comment);
}

.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.profile-header img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.profile-header h3 {
  margin: 10px 0 5px;
}

.profile-header p {
  color: #666;
  margin: 0;
}

.profile-info p {
  margin: 10px 0;
  font-size: 0.9rem;
}

/* API Key Generator Styles */
.api-key-title {
  color: var(--purple);
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.api-key-description {
  color: var(--comment);
  margin-bottom: 2rem;
}

.api-key-modal-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(40, 42, 54, 0.8);
}

.api-key-modal {
  background-color: var(--current-line);
  border-radius: 8px;
  padding: 2rem;
  max-width: 500px;
  width: 100%;
  border: 1px solid var(--comment);
}

.api-key-modal-title {
  color: var(--purple);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.api-key-form-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.api-key-input-label {
  color: var(--cyan);
  display: block;
  margin-bottom: 0.5rem;
}

.api-key-input {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--background);
  border: 1px solid var(--comment);
  border-radius: 4px;
  color: var(--foreground);
}

.api-key-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.api-key-list-title {
  color: var(--purple);
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

.api-key-list-section {
  margin-top: 3rem;
}

.api-key-panel {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 42, 54, 0.8);
  z-index: 1000;
  padding: 1rem;
}

.api-key-panel-content {
  background-color: var(--current-line);
  border-radius: 8px;
  padding: 2rem;
  max-width: 500px;
  width: 100%;
  border: 1px solid var(--comment);
  position: relative;
}

.api-key-panel-title {
  color: var(--pink);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.api-key-panel-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--foreground);
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s;
}

.api-key-panel-close:hover {
  color: var(--pink);
}

.api-key-panel-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.api-key-panel-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.api-key-panel-label {
  color: var(--purple);
  font-size: 0.9rem;
}

.api-key-panel-input {
  background-color: var(--background);
  border: 1px solid var(--comment);
  color: var(--foreground);
  padding: 0.75rem;
  border-radius: 4px;
  width: 100%;
}

.api-key-panel-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.api-key-panel-value {
  background-color: var(--background);
  padding: 1rem;
  border-radius: 4px;
  font-family: monospace;
  position: relative;
  word-break: break-all;
  margin-bottom: 1rem;
}

.api-key-panel-copy {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  color: var(--comment);
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s;
}

.api-key-panel-copy:hover {
  color: var(--pink);
}

.api-key-panel-warning {
  color: var(--orange);
  font-size: 0.9rem;
  margin-top: 1rem;
}

.api-key-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--current-line);
  margin-top: 1rem;
}

.api-key-table th {
  background-color: var(--background);
  color: var(--purple);
  font-weight: 500;
  text-align: left;
  padding: 1rem;
  font-size: 0.9rem;
}

.api-key-table td {
  padding: 1rem;
  color: var(--foreground);
  border-top: 1px solid var(--comment);
}

.api-key-table tbody tr:hover {
  background-color: var(--background);
}

.api-key-empty {
  text-align: center;
  padding: 2rem;
  background-color: var(--current-line);
  border-radius: 8px;
  color: var(--comment);
}

.api-key-empty-subtitle {
  color: var(--comment);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* New Pages Styles */
.page-container {
  padding-top: 84px;
  min-height: 100vh;
  background-color: var(--background);
}

.content-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.content-section h1 {
  color: var(--purple);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

/* Products Page Styles */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.product-card {
  background-color: var(--current-line);
  padding: 2rem;
  border-radius: 8px;
  transition: transform 0.2s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card h2 {
  color: var(--cyan);
  margin-bottom: 1rem;
}

.product-card h3 {
  color: var(--pink);
  margin: 1rem 0;
}

.product-description {
  color: var(--comment);
  margin-bottom: 1.5rem;
}

.product-card ul {
  list-style: none;
  padding: 0;
}

.product-card ul li {
  color: var(--foreground);
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.product-card ul li:before {
  content: "•";
  color: var(--purple);
  position: absolute;
  left: 0;
}

/* About Page Styles */
.about-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-content section {
  margin-bottom: 3rem;
}

.about-content h2 {
  color: var(--cyan);
  margin-bottom: 1rem;
}

.about-content p {
  color: var(--foreground);
  line-height: 1.6;
  margin-bottom: 1rem;
}

.about-content ul {
  list-style: none;
  padding: 0;
}

.about-content ul li {
  color: var(--foreground);
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.about-content ul li:before {
  content: "•";
  color: var(--purple);
  position: absolute;
  left: 0;
}

/* Pricing Page Styles */
.pricing-intro {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  color: var(--comment);
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.pricing-card {
  background-color: var(--current-line);
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s;
}

.pricing-card.featured {
  border: 2px solid var(--purple);
  transform: scale(1.05);
}

.pricing-card:hover {
  transform: translateY(-5px);
}

.pricing-card.featured:hover {
  transform: scale(1.05) translateY(-5px);
}

.pricing-card h2 {
  color: var(--cyan);
  margin-bottom: 1.5rem;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.pricing-card ul li {
  color: var(--foreground);
  margin-bottom: 0.8rem;
  padding-left: 1.5rem;
  position: relative;
  text-align: left;
}

.pricing-card ul li:before {
  content: "✓";
  color: var(--green);
  position: absolute;
  left: 0;
}

.btn-delete {
  background-color: var(--red);
  color: var(--background);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: opacity 0.2s;
}

.btn-delete:hover {
  opacity: 0.9;
}
/* Contact Page Styles */
.contact-info {
  margin-bottom: 2rem;
  color: var(--comment);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: var(--cyan);
}

.form-group input,
.form-group textarea {
  background-color: var(--background);
  border: 1px solid var(--comment);
  color: var(--foreground);
  padding: 0.75rem;
  border-radius: 4px;
  width: 100%;
}
